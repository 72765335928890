<template>
  <div class="sample-editor-js d-flex flex-column align-center pa-10">
    <v-card class="card flex-grow-1 pa-5 mb-5">
      <v-btn outlined color="primary" @click="save">json</v-btn>
      <div>{{json}}</div>
    </v-card>
    <v-card class="card flex-grow-1 pa-5">
      <EditorJsContainer v-if="init" ref="editor" :projectId="0" :data="data"></EditorJsContainer>
    </v-card>
  </div>
</template>
<script>
import EditorJsContainer from '@/components/atomic/organisms/EditorJsContainer.vue';
import {RepositoryFactory} from '../../api/RepositoryFactory'

import { test_data } from './SampleEditorJS_data';

//https://bt-daisuke-ishii.notion.site/BB-797e41ac4ce34939b05f1fbcdba8612b

export default {
  components: {
    EditorJsContainer,
  },
  data() {
    return {
      json: null,
      loadData: null,
      init: false,
    }
  },
  computed: {
    data() {
      // return test_data;
      return this.loadData;
    }
  },

  async mounted() {
      const overviewRepository = RepositoryFactory.get('overview')
      const ret = await overviewRepository.fetched(8, 1);
      
      this.loadData = JSON.parse(ret.data.data.overview.data);
      this.init = true;
  },

  methods: {
    async save() {
      const saveData = await this.$refs.editor.save();

      const payload = {
        overview: {
          data: JSON.stringify(saveData)
        },
        userId: 1,
      };

      const overviewRepository = RepositoryFactory.get('overview')
      const ret = await overviewRepository.updated(0, payload);

      this.json = ret;
    },
  },
}
</script>
<style lang="scss" scoped>
.sample-editor-js {
  background-color: skyblue;
  overflow-y: auto;
  height: 100vh;

  .card {
    width: 100%;
    max-width: 1000px;
  }
}
</style>
